import * as _ from 'lodash-es';

import {ActivatedRoute, UrlSerializer, UrlTree} from "@angular/router";
import {AppState, getUserState} from "../../ngrx/app.reducres";

import { Injectable } from '@angular/core';
import {LoginConfig} from "../../login/login.config";
import {Store} from "@ngrx/store";
import {environment} from "../../../environments/environment";
import {take} from 'rxjs/operators';

@Injectable()
export class UrlService {

  constructor(private _store: Store<AppState>,  private _activatedRoute: ActivatedRoute,
              private _urlSerializer: UrlSerializer) { }

  /**
   * This shared-method is responsible for parsing the relevant data from the Url and response with it.
   *
   * @returns {
   *    redirectQp,
   *    isRedirectedFromReachOut,
   *    errorQp,
   *    sfQp,
   *    appQp,
   *    dataQp,
   *    isRedirectedFromSFNA,
   *    isRedirectedFromZi,
   *    isRedirectedFromDozi,
   *    isLoginInNewTab
   * }
   * **/
  getInfoFromUrl() {
    const redirectQp = _.get(this._activatedRoute, 'snapshot.queryParams.redirect', '');
    const isReachout = _.get(this._activatedRoute, 'snapshot.queryParams.isReachout', '') === 'true';
    const isEngageExt = _.get(this._activatedRoute, 'snapshot.queryParams.isEngageExt', '') === 'true';
    const isOutlookExt = _.get(this._activatedRoute, 'snapshot.queryParams.isOutlookExt', '') === 'true';
    const isRedirectFromOutlook = isOutlookExt ? this.getRedirectForOutlook() : '';
    const {isRedirectedFromDevApp, isRedirectedFromReachOut, isRedirectedFromDoziReachOut, isRedirectedFromZiReachOut, isRedirectedFromSFNA, isRedirectedFromMDNA, isRedirectedFromZi, isRedirectedFromDozi, isRedirectedFromRecruiter, isRedirectedFromMarketingCloud, isRedirectedFromZIBackOffice, isRedirectedFromExtension, isRedirectForEngageExt, isRedirectedFromAdmin, isRedirectedFromDoziLite, isRedirectedFromDevPortal} = this.getRedirectUrlInfo(redirectQp, isReachout, isEngageExt);
    const errorQp = _.get(this._activatedRoute, 'snapshot.queryParams.error', '');
    const sfQp = _.get(this._activatedRoute, 'snapshot.queryParams.sf', '');
    const appQp = _.get(this._activatedRoute, 'snapshot.queryParams.app', '');
    const dataQp = _.get(this._activatedRoute, 'snapshot.queryParams.data', '');
    const provisionQp = _.get(this._activatedRoute, 'snapshot.queryParams.provision', false);
    const toggleFromQp = _.get(this._activatedRoute, 'snapshot.queryParams.toggleFrom', '');
    const toggleToQp = _.get(this._activatedRoute, 'snapshot.queryParams.toggleTo', '');
    const toggleFromUrlQp = _.get(this._activatedRoute, 'snapshot.queryParams.toggleFromUrl', '');
    const toggleToUrlQp = _.get(this._activatedRoute, 'snapshot.queryParams.toggleToUrl', '');
    const toggleFlowErrorQp = _.get(this._activatedRoute, 'snapshot.queryParams.toggleFlowError', '');
    const autoLoginQp = _.get(this._activatedRoute, 'snapshot.queryParams.autoLogin', '');
    const uuidTokenQp = _.get(this._activatedRoute, 'snapshot.queryParams.uuidToken', '');
    const userIdQp = _.get(this._activatedRoute, 'snapshot.queryParams.userId', '');
    const toggleFlowQp = _.get(this._activatedRoute, 'snapshot.queryParams.toggleFlow', '');
    const loginUserQpUc = _.get(this._activatedRoute, 'snapshot.queryParams.loginUser', 0);
    const loginUserQpLc = _.get(this._activatedRoute, 'snapshot.queryParams.loginuser', 0);
    const oauthLoginErrorLegacyQp = _.get(this._activatedRoute, 'snapshot.queryParams.OAuthLoginError', '');
    const oauthLoginErrorQp = _.get(this._activatedRoute, 'snapshot.queryParams.oauthLoginErr', '');
    const utmCampaign = _.get(this._activatedRoute, 'snapshot.queryParams.utm_campaign', '');
    const ziReferrer = _.get(this._activatedRoute, 'snapshot.queryParams.ziReferrer', '');
    const loginAsMultiPlatforms = _.get(this._activatedRoute, 'snapshot.queryParams.loginAsMultiPlatforms', '');
    const domain = window.location.hostname;
    const isLziLoginSubdomain = domain && domain.includes(LoginConfig.LEGACY_LOGIN_URL_IDENTIFIER);
    const isLoginInNewTab = _.get(this._activatedRoute, 'snapshot.queryParams.newtab', '') === 'true';
    const isRedirectedFromNativePlugin = redirectQp && redirectQp.includes(LoginConfig.NATIVE_PLUGIN_PREFIX_URL);
    const clientId = _.get(this._activatedRoute, 'snapshot.queryParams.client_id', '');
    const redirectUri = _.get(this._activatedRoute, 'snapshot.queryParams.redirect_uri', '');
    const codeChallenge = _.get(this._activatedRoute, 'snapshot.queryParams.code_challenge');
    const scopes: string | null = _.get(this._activatedRoute, 'snapshot.queryParams.scopes');
    const state = _.get(this._activatedRoute, 'snapshot.queryParams.state');

    return {
      redirectQp,
      isRedirectedFromDoziReachOut,
      isRedirectedFromZiReachOut,
      isRedirectedFromReachOut,
      errorQp,
      sfQp,
      appQp,
      dataQp,
      isRedirectedFromSFNA,
      isRedirectedFromMDNA,
      isRedirectedFromZi,
      isRedirectedFromDozi,
      isRedirectedFromRecruiter,
      isRedirectedFromMarketingCloud,
      isRedirectedFromZIBackOffice,
      isRedirectedFromDevApp,
      isRedirectedFromExtension,
      isRedirectedFromDevPortal,
      provisionQp,
      toggleFromQp,
      toggleToQp,
      toggleFromUrlQp,
      toggleToUrlQp,
      toggleFlowErrorQp,
      autoLoginQp,
      uuidTokenQp,
      userIdQp,
      toggleFlowQp,
      loginUserQp: loginUserQpUc || loginUserQpLc,
      oauthLoginErrorLegacyQp,
      oauthLoginErrorQp,
      utmCampaign,
      isReachout,
      ziReferrer,
      loginAsMultiPlatforms,
      isLziLoginSubdomain,
      isEngageExt,
      isRedirectForEngageExt,
      isRedirectFromOutlook,
      isRedirectedFromAdmin,
      isRedirectedFromDoziLite,
      isLoginInNewTab,
      isRedirectedFromNativePlugin,
      clientId,
      redirectUri,
      codeChallenge,
      scopes,
      state,
    };
  }

  /**
   * This method is responsible for the final redirectUrl logic.
   *
   * Redirect QP is editable by the user therefore we're validating it
   *
   *
   * **/
  getLoginFinalRedirectUrl() {
    let userState;
    this._store.select(getUserState).pipe(take(1)).subscribe((user) => userState = user);
    //
    const redirectUrlOnState = _.get(userState, 'redirectUrl');
    //
    return redirectUrlOnState;
  }

  getRedirectForOutlook() {
    return environment.engage_url_enterprise + LoginConfig.OUTLOOK_POSTFIX_URL;
  }

  getRedirectUrlInfo(redirectUrl = '', isReachout = false, isEngageExt = false) {
    const isRedirectedFromDevApp = this.getUrlValue(redirectUrl, 'dev.zoominfo.com');
    //
    const isRedirectedFromSFNA = this.getUrlValue(redirectUrl, LoginConfig.SFNA_PREFIX_URL);
    const isRedirectedFromMDNA = this.getUrlValue(redirectUrl, LoginConfig.MDNA_PREFIX_URL);
    const isRedirectedFromNativePlugin = this.getUrlValue(redirectUrl, LoginConfig.NATIVE_PLUGIN_PREFIX_URL);
    //
    const isRedirectedFromZi = this.getUrlValue(redirectUrl, environment.zi_url_community) || this.getUrlValue(redirectUrl, environment.zi_url_enterprise);
    const isRedirectedFromDozi = this.getUrlValue(redirectUrl, environment.dozi_url_community) ||
                                  this.getUrlValue(redirectUrl, environment.dozi_url_enterprise) ||
                                  this.getUrlValue(redirectUrl, environment.dozi_regression_url);
    const isRedirectedFromRecruiter = this.getUrlValue(redirectUrl, environment.recruiter_url_community) || this.getUrlValue(redirectUrl, environment.recruiter_url_enterprise);

    const isRedirectedFromMarketingCloud = this.getUrlValue(redirectUrl, environment.marketing_cloud_url_enterprise);

    const isRedirectedFromZIBackOffice = this.getUrlValue(redirectUrl, environment.zi_backoffice_url);
    const isRedirectedFromDoziLite = this.getUrlValue(redirectUrl, environment.dozi_lite_url) || this.getUrlValue(redirectUrl, environment.dozi_lite_regression_url) ||
        this.getUrlValue(redirectUrl, environment.dozi_lite_new_url) || this.getUrlValue(redirectUrl, environment.dozi_lite_new_regression_url);
    // ReachOut related
    const isRedirectedFromDoziReachOut = (this.getUrlValue(redirectUrl, LoginConfig.DOZI_REACHOUT_PREFIX_URL) && isRedirectedFromDozi) || isReachout;
    const isRedirectedFromZiReachOut = this.getUrlValue(redirectUrl, LoginConfig.ZI_REACHOUT_PREFIX_URL) && isRedirectedFromZi;
    const isRedirectedFromReachOut = isRedirectedFromDoziReachOut || isRedirectedFromZiReachOut;
    //
    const isRedirectedFromExtension = isRedirectedFromReachOut || isRedirectedFromSFNA || isRedirectedFromMDNA;
    const shouldUseSecuredCookie = isRedirectedFromDoziReachOut || isRedirectedFromNativePlugin;

    const isRedirectedFromDevPortal = this.getUrlValue(redirectUrl, environment.devportal_domain);

    // Engage Extension Related
    let isRedirectForEngageExt = isEngageExt ? environment.engage_url_enterprise + LoginConfig.ENGAGE_EXT_POSTFIX_URL : '';
    if (this.isEngageExtensionOnGmail() && !!isRedirectForEngageExt) {
      isRedirectForEngageExt += LoginConfig.ENGAGE_EXT_GMAIL_POSTFIX;
    }

    //Admin Portal
    const isRedirectedFromAdmin = this.getUrlValue(redirectUrl, environment.admin_url_enterprise) || this.getUrlValue(redirectUrl, environment.admin_regression_url);

    return {
      isRedirectedFromDevApp,
      isRedirectedFromDoziReachOut,
      isRedirectedFromZiReachOut,
      isRedirectedFromReachOut,
      isRedirectedFromSFNA,
      isRedirectedFromMDNA,
      isRedirectedFromZi,
      isRedirectedFromDozi,
      isRedirectedFromRecruiter,
      isRedirectedFromMarketingCloud,
      isRedirectedFromZIBackOffice,
      isRedirectedFromExtension,
      shouldUseSecuredCookie,
      isRedirectForEngageExt,
      isRedirectedFromAdmin,
      isRedirectedFromDoziLite,
      isRedirectedFromDevPortal
    };
  }
  /**
   * Figure out if the provided URL is one of Zoom's application's domain
   * **/
  isUrlAllowedForRedirect(url) {
    if (url) {
      try {
        const hostname = new URL(url).hostname;
        return LoginConfig.ALLOWED_REDIRECT_URL_HOSTNAMES.some((allowedHostname) => hostname.endsWith(allowedHostname));
      } catch (err) {
        console.error('ERROR in isUrlAllowedForRedirect :: ', err);
      }
    }
    return false;
  }

  /**
   * Method to return Boolean value for redirect Url
   * @param redirectUrl
   * @param url
   * @private
   */
  getUrlValue(redirectUrl: string, url: string) {
    return redirectUrl && redirectUrl.includes(url);
  }

  /**
   * checks if extension is running om gmail domain
   * this is required for engage extension to redirect back
   * Engage extension supports Gmail and we specifically set a redirection for gmail
   */
  isEngageExtensionOnGmail() {
    const gmailUrls = ['https://mail.google.com', 'https://inbox.google.com'];
    if (gmailUrls.indexOf(window.location.origin) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /** If the url is from Skilljar, return the params. If not, return null. */
  static getSkilljarUrlParams(url: string) {
    const searchParams = new URLSearchParams(url);
    const SAMLRequest = searchParams.get('SAMLRequest');
    const RelayState = searchParams.get('RelayState');
    const Signature = searchParams.get('Signature');
    const SigAlg = searchParams.get('SigAlg');
    if (decodeURI(url).includes(environment.skilljarUrlPartial) && SAMLRequest && RelayState && Signature && SigAlg) {
      return { SAMLRequest, RelayState, Signature, SigAlg };
    }
    return null;
  }
}
