/**
 * Production environment config
 */

const website_domain = "https://www.zoominfo.com";
const base_login_canonical = "https://login.zoominfo.com";
const cookies_domain = ".zoominfo.com";
const app_domain = "https://login.zoominfo.com";
const lb_domain = website_domain;

export const environment = {
  // Production specific configuration
  production: true,
  name: "production",
  app_domain,
  website_domain,
  base_login_canonical,
  cookies_domain,
  bs_regular_domain: "https://app.zoominfo.com/bizservices",
  lb_domain,
  login_backend_domain: "https://login.zoominfo.com/api",
  grow_backend_domain: "https://app.zoominfo.com/anura",
  amplitude_api_key: "14ff67f4fc837e2a741f025afb61859c",

  ums_password_reset_url: "https://login.zoominfo.com/gw/ziapi/ums/v2/passwordReset",  // NOSONAR
  sso_redirect_url: "https://gw-app.zoominfo.com/okta/ssoRedirect",
  //
  dozi_url_enterprise: "https://app.zoominfo.com",
  dozi_url_community: "https://app.zoominfo.com",
  devportal_domain: "https://developer.zoominfo.com", // TODO: Should be update in future once deploy the developer portal to pre prod.
  dozi_url_reachout: "https://app.zoominfo.com/#/reachout/routing",
  dozi_regression_url: "https://dozi-regression.zoominfo.com",
  dozi_lite_url: "https://apps.zoominfo.com/zi-lite/#/",
  dozi_lite_new_url: "https://zi-lite.zoominfo.com/#/",
  dozi_lite_regression_url: "https://apps-regression.zoominfo.com/zi-lite/",
  dozi_lite_new_regression_url: "https://zi-lite-regression.zoominfo.com/",
  //
  zi_url_enterprise: "https://go.zoominfo.com",
  zi_url_community: "https://ce.zoominfo.com",
  //
  recruiter_url_enterprise: "https://recruiter-app.zoominfo.com",
  recruiter_url_community: "https://recruiter-app.zoominfo.com",
  recruiter_url_reachout: "https://recruiter-app.zoominfo.com/reachout/routing",
  //
  marketing_cloud_url_enterprise: "https://marketingos.zoominfo.com",
  //
  engage_url_enterprise: "https://engage-app.zoominfo.com",
  //
  admin_url_enterprise: "https://admin.zoominfo.com/",
  admin_regression_url: "https://admin-regression.zoominfo.com/",
  //
  tam_url_enterprise: "https://tam.zoominfo.com/",
  //
  operations_url_enterprise: "https://operationsos.zoominfo.com/",
  //
  zi_backoffice_url: "https://backoffice.zoominfo.com/backoffice/home/index",
  //
  microsoft_clientId: "893ba34a-549f-4e3e-befa-d1386d0e5eb6",
  google_clientId:
    "422477201612-eba4lqojgge8ubsufbvbd19n06c4tdtu.apps.googleusercontent.com",
  GOOGLE_TAG_MANAGER_ID: "GTM-PHWTRTJ",
  //okta login configs
  googleIdpId: "0oab4d8z5l0FcXt9H696",
  microsoftIdpId: "0oab4drgiEFNcQtF7696",
  sfIdpId: "0oa6r202vpHAvosus697",
  oktaClientId: "0oa99dsmbnAxlevF3696",
  oktaLoginUrl: "https://okta-login.zoominfo.com",
  oktaIssuer: "https://okta-login.zoominfo.com/oauth2/default",
  oktaLegacyIssuer: 'https://zoominfo-auth.okta.com/oauth2/default',
  sm_ping_url: "https://login.zoominfo.com/gw/ziapi/okta/ping",
  sm_refresh_storage_url: "https://login.zoominfo.com/gw/ziapi/refreshToken",
  sm_refresh_access_token_url: "https://login.zoominfo.com/gw/ziapi/refreshAccessToken",
  sm_auto_provisioning_login_url: "https://gw-app.zoominfo.com/gw/ziapi/autoProvisioningLogin",
  oktaDeleteSessionUrl: "https://okta-login.zoominfo.com/api/v1/users/me/lifecycle/delete_sessions",
  oktaGetSessionsMeUrl: "https://okta-login.zoominfo.com/api/v1/sessions/me",

  skilljarUrlPartial: 'zoominfo-auth_skilljarproduction_1',
  // ZI-Lite sign up URL
  signup_url: 'https://signup.zoominfo.com/#/',
  settings_url: 'https://app.zoominfo.com/ziapi/settings/api',
  play_store_url: 'https://play.google.com/store/apps/details?id=com.zoominfo.enterprise&hl=en',
  partner_api_url: 'https://gw-app.zoominfo.com/gw/ziapi/api/v1/partner-scope/apps',
};
